import React from 'react'

const PageNotFound = () => {
  return (
    <div>
      <h1>Page Not Found 404</h1>
    </div>
  )
}

export default PageNotFound
