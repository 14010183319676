import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import BusinessUnits from './Pages/BusinessUnits';
import Contact from './Pages/Contact';
import HomePage from './Pages/HomePage';
import OurPartners from './Pages/OurPartners';
import PageNotFound from './Pages/PageNotFound';
import OurCustomers from './Pages/OurCustomers';
import '@fortawesome/fontawesome-svg-core/styles.css';

function App() {
 return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/BusinessUnits" element={<BusinessUnits/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="/HomePage" element={<HomePage/>} />
        <Route path="/OurPartners" element={<OurPartners/>} />
        <Route path="/OurCustomers" element= {<OurCustomers/>}/>
        <Route element={<PageNotFound/>} />
      </Routes>
    </Router>
 );
}

export default App;
