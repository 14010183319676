import React from 'react'
import { NavLink } from 'react-router-dom'
import '../css/Footer.css'
import logo from '../Components/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='address'>
        <img src={logo} alt="logo" />
        <p style={{color: 'white', fontSize: '16px'}}>Head Office: Office 316, Floor F1, Jeff Heights Gulberg III, Lahore.</p>
        <p style={{color: 'white', fontSize: '16px'}}>LAHORE | ISLAMABAD | KARACHI</p>
        <p style={{color: 'white', fontSize: '16px'}}>GILGIT | PESHAWAR</p>
      </div>
      <div className='quicklinksfooter'>
        <h2 style={{color: 'white'}}>Quick Links</h2>
        <NavLink to='/' style={{textDecoration: 'none'}}>
          <p style={{color: 'white', fontSize: '20px'}}>Home</p>
        </NavLink>
        <NavLink to='/About' style={{textDecoration: 'none'}}>
          <p style={{color: 'white', fontSize: '20px'}}>About Us</p>
        </NavLink>
        <NavLink to='/Contact' style={{textDecoration: 'none'}}>
          <p style={{color: 'white', fontSize: '20px'}}>Get A Quote</p>
        </NavLink>
        <NavLink to='/Contact' style={{textDecoration: 'none'}}>
          <p style={{color: 'white', fontSize: '20px'}}>Contact</p>
        </NavLink>
      </div>
      <div className='importantlinksfooter'>
      <h2 style={{color: 'white'}}>Important Links</h2>
      <NavLink to='https://amdcclinic.com/wp/#' style={{textDecoration: 'none'}}>
        <p style={{color: 'white', fontSize: '20px'}}>AMDC</p>
      </NavLink>
      <NavLink to='/OurPartners' style={{textDecoration: 'none'}}>
        <p style={{color: 'white', fontSize: '20px'}}>Partners</p>
      </NavLink>
      </div>
      <div className='letsconnect'>
        <h2 style={{color: 'white'}}>Lets Connect</h2>
        <p style={{color: 'white', fontSize: '18px'}}>Connect with Digitel, build your network, make great business.</p>
        <NavLink to='https://www.facebook.com/DigiTelGlobe'>
        <FontAwesomeIcon style={{color: 'white', fontSize: '20px'}} icon={faFacebook}></FontAwesomeIcon>
        </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <NavLink to='https://twitter.com/digitelglobe'>
          <FontAwesomeIcon style={{color: 'white', fontSize: '20px'}} icon={faXTwitter}></FontAwesomeIcon>
        </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <NavLink to='https://www.youtube.com/@digitelglobe'>
          <FontAwesomeIcon style={{color: 'white', fontSize: '20px'}} icon={faYoutube}></FontAwesomeIcon>
        </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <NavLink>
          <FontAwesomeIcon style={{color: 'white', fontSize: '20px'}} icon={faLinkedin}></FontAwesomeIcon>
        </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <NavLink to='https://www.instagram.com/digitelglobe/?hl=en'>
          <FontAwesomeIcon style={{color: 'white', fontSize: '20px'}} icon={faInstagram}></FontAwesomeIcon>
        </NavLink>
      </div>
    </div>
  )
}

export default Footer;