import {Footer, Main, Navbar} from '../Components'

import React from 'react'

const Home = () => {
  return (
    <>
    <Navbar />
    <Main/>
    <Footer/>
    </>
  )
}

export default Home;