import React from 'react'
import Navbar from '../Components/Navbar'
import '../css/contact.css'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import {faEnvelope, faLocationDot, faPhoneFlip} from '@fortawesome/free-solid-svg-icons'
import Footer from '../Components/Footer'
import ContactForm from '../Pages/ContactForm'

const Contact = () => {
  return (
    <div>
      <Navbar/>

      <h1 style={{textAlign: 'Center', color: 'blue', fontFamily: 'sans-serif', fontSize: '40px', marginTop: '50px'}}>Contact Us</h1>
      <hr />
      <br /><br />
      <div className='getintouchdiv'>
        <h1 style={{textAlign: 'center', color: 'black'}}>Get in touch</h1>
        <div className='linksalsocial'>
        <NavLink to='https://www.facebook.com/DigiTelGlobe'>
            <FontAwesomeIcon className='alfacebook' icon={faFacebook}></FontAwesomeIcon>
        </NavLink>
        <NavLink to='https://twitter.com/digitelglobe'>
          <FontAwesomeIcon className='twitteralX' icon={faXTwitter}></FontAwesomeIcon>
        </NavLink>
        <NavLink to='https://www.youtube.com/@digitelglobe'>
          <FontAwesomeIcon className='alyoutube' icon={faYoutube}></FontAwesomeIcon>
        </NavLink>
        <NavLink to='https://www.linkedin.com/company/digitelglobe/'>
          <FontAwesomeIcon className='allinkedin' icon={faLinkedin}></FontAwesomeIcon>
        </NavLink>
        <NavLink to='https://www.instagram.com/digitelglobe/?hl=en'>
          <FontAwesomeIcon className='alinstagram' icon={faInstagram}></FontAwesomeIcon>
        </NavLink>
        </div>
      </div>
      <div className='alourlocations'>
        <FontAwesomeIcon className='allocationdot' icon={faLocationDot}></FontAwesomeIcon>
        <h2 style={{marginLeft: '10px'}}>Our Locations</h2>
        <ul className='allocationul'>
          <li style={{fontSize: '18px', color: 'grey'}}>Lahore: Office 316, Floor F1 Jeff Heights Gulberg III, Lahore.</li>
          <li style={{fontSize: '18px', color: 'grey'}}>Islamabad: Office 711, Floor 7, Zara Heights Sector H-13 Islamabad.</li>
          <li style={{fontSize: '18px', color: 'grey'}}>Karachi: Suite 19, Mezzanine Floor, Sahil Promenade, Block 3, Clifton, Karachi.</li>
          <li style={{fontSize: '18px', color: 'grey'}}>Peshawar: Office#2, First Floor, Opp Darmangi Garden, St 2, Main Warsak Road, Peshawar.</li>
          <li style={{fontSize: '18px', color: 'grey'}}>Gilgit: Office# 307, ZS Plaza, Opp. Radio Pakistan, Shahrah-e-Quaid-e-Azam, Jutial Cantt, Gilgit.</li>
        </ul>
      </div>
      <div className='alcallemail'>
        <div className='alcall'>
          <FontAwesomeIcon className='alphone' icon={faPhoneFlip}></FontAwesomeIcon>
          <br /><br /><br />
          <h2 style={{marginLeft: '20px'}}>Call Us On</h2>
          <br />
          <p style={{marginLeft: '20px', color: 'grey', fontSize: '18px'}}>+92 335 333 1140</p>
          <p style={{marginLeft: '20px', color: 'grey', fontSize: '18px'}}>+92 335 333 1145</p>
        </div>
        <div className='alemail'>
          <FontAwesomeIcon className='alenvelope' icon={faEnvelope}></FontAwesomeIcon>
          <br /><br /><br />
          <h2 style={{marginLeft: '20px'}}>Email Us</h2>
          <br />
          <p style={{marginLeft: '20px', color: 'grey', fontSize: '18px'}}>info@DigiTel.com.pk</p>
        </div>
      </div>
      <h1 style={{textAlign: 'center', color: 'black', fontFamily: 'sans-serif', fontSize: '40px', marginTop: '70px'}}>Send us a Message</h1>
      <ContactForm/>
      <br /><br /><br />
      <Footer/>
    </div>
  )
}

export default Contact;
