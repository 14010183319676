import React from 'react'
import Navbar from '../Components/Navbar'
import '../css/BusinessUnits.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faPrint, faRocket, faVideo, faBug, faCubes, faEnvelope, faDog } from '@fortawesome/free-solid-svg-icons'
import Footer from '../Components/Footer';

const BusinessUnits = () => {
  return (
    <div>
      <Navbar />
      <br /><br />
      <h1 style={{textAlign: 'center', color: 'blue', fontFamily: 'sans-serif'}}>Specialization</h1>
      <hr />
      <br />
      <div className='parent8'>
        <div className='automationbusiness2'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faClock}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Automation</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>DigiTel is now offering CMS, ERP, LMS, MIS, CRM, HRMS, Inventory Management System & Website Development in its automation Domain.</p>
        </div>
        <div className='cloudsolutionsbusiness'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faUser}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Cloud Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>DigiTel offers the most advanced cloud solutions that help organizations drive innovation and business transformation by increasing business agility, lowering costs, and reducing IT complexity. Its cloud portfolio includes SAAS, PAAS & IAAS.</p>
        </div>
        <div className='vitualizationsolutions'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faPrint}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Virtualization Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>DigiTel delivers VMware implementation solutions & 24x7 on-site/off-site support to organizations of all sizes. JBS is the principal partner for VMware in the area of Datacenter Virtualization, the first and only partner in Pakistan. This is the highest level of partnership.</p>
        </div>
      </div>
      <div className='parent8'>
        <div className='cybersecuritysolutions'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faRocket}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Cyber Security Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>In order to provide your business with a secure foundation, cybersecurity is a requirement. DigiTel has a vast portfolio of cybersecurity services that aim to help organizations address thei security challenges through a more proactive approach.</p>
        </div>
        <div className='technologysolutions2'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faVideo}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Technology Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>By updating your applications and IT infrastructure, DigiTel can help you reduce your business costs and move towards a more sustainable business model. We've partnered up with only the best OEMs for infrastructure services as well as enterprise solutions.</p>
        </div>
        <div className='frontendsolutions'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faBug}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Frontend Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>When it comes to front-end devices. we aim to provide you with excellent quality and powerful hardware. We're able to provide you with limitless options by offering you the complete Grandstream portfolio.</p>
        </div>
      </div>
      <div className='parent8'>
        <div className='socialmediamarketingbusiness'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faCubes}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Social Media Marketing</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>Innovation is at the heart of everything we do. DigiTel enables you to scale your business through digital services and cutting-edge technology. By helping you buikd your digital strategy we bring you one step closer to creating disruption and moving beyond competitive advantage.</p>
        </div>
        <div className='datacentersolutionsbusiness'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faEnvelope}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Data Center Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>Being a tier I Authorized Solutions & Service Provider of Vertiv, DigiTel offers an entire range od data center-related products including but not limited to Power, HVAC (Heating, Ventilation, and air conditioning), Racks & Enclosures/Cold Aisle Containment, environmental monitoring systems, and UPS/Rectifiers.</p>
        </div>
        <div className='infrastructuresolutionsbusiness'>
        <FontAwesomeIcon style={{color: 'blue', marginTop: '30px', marginLeft: '30px', fontSize: '40px'}} icon={faDog}></FontAwesomeIcon>
        <h2 style={{marginLeft: '30px'}}>Infrastructure Solutions</h2>
        <p style={{marginLeft: '30px', fontSize: '18px', lineHeight: '30px'}}>Being a Tier 1 partner of HPE, we are able to offer the complete range of HPE's portfolio to our customers while providing them with full support from installation to maintenance. Our product specialists bring you 24/7 on-sight and off-sight support to ensure efficiency and improved agility.</p>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BusinessUnits;