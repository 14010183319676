import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../css/Projectcarousel.css';
import aepl from '../Components/images/Achievements/AEPL.jpg';
import aiou from '../Components/images/Achievements/aiou.jpg';
import bu from '../Components/images/Achievements/bahria university.jpg';
import cottonweb from '../Components/images/Achievements/cotton web.jpg';
import cwl from '../Components/images/Achievements/cwl.jpg';
import dellemc from '../Components/images/Achievements/dellemc.jpg';
import grandstream from '../Components/images/Achievements/grandstream.jpg';
import h3c from '../Components/images/Achievements/h3c.jpg';
import lci from '../Components/images/Achievements/lci.jpg'
import lums from '../Components/images/Achievements/lums.jpg'
import ntdc from '../Components/images/Achievements/ntdc.jpg'
import na from '../Components/images/Achievements/nust attom.jpg'
import nust from '../Components/images/Achievements/NUST.jpg'
import pesco from '../Components/images/Achievements/pesco.jpg'
import pmd from '../Components/images/Achievements/pmd.jpg'
import riphah from '../Components/images/Achievements/riphah.jpg'
import sanstorage from '../Components/images/Achievements/sanstorage.jpg'
import seedsacademia from '../Components/images/Achievements/seedsacademia.jpg'
import sefam from '../Components/images/Achievements/sefam.jpg'
import sophos from '../Components/images/Achievements/sophos.jpg'
import uil from '../Components/images/Achievements/uil.jpg'
import willshire from '../Components/images/Achievements/wilshire.jpg'

const ProjectsCarousel = () => {
  return (
      <div>
      <Carousel showArrows={true} infiniteLoop={true} autoPlay={true}>
        <div className='carousel-item'>
          <img src={aepl} alt="aepl" />
        </div>
        <div className='carousel-item'>
          <img src={aiou} alt="aiou" />
        </div>
        <div className='carousel-item'>
          <img src={bu} alt="bu" />
        </div>
        <div className='carousel-item'>
          <img src={cottonweb} alt="cw" />
        </div>
        <div className='carousel-item'>
          <img src={cwl} alt="cwl" />
        </div>
        <div className='carousel-item'>
          <img src={dellemc} alt="dellemc" />
        </div>
        <div className='carousel-item'>
          <img src={grandstream} alt="grandstream" />
        </div>
        <div className='carousel-item'>
          <img src={h3c} alt="h3c" />
        </div>
        <div className='carousel-item'>
            <img src={lci} alt="lci" />
        </div>
        <div className='carousel-item'>
            <img src={lums} alt="lums" />
        </div>
        <div className='carousel-item'>
            <img src={ntdc} alt="ntdc" />
        </div>
        <div className='carousel-item'>
            <img src={na} alt="na" />
        </div>
        <div className='carousel-item'>
            <img src={nust} alt="nust" />
        </div>
        <div className='carousel-item'>
            <img src={pesco} alt="pesco" />
        </div>
        <div className='carousel-item'>
            <img src={pmd} alt="pmd" />
        </div>
        <div className='carousel-item'>
            <img src={riphah} alt="riphah" />
        </div>
        <div className='carousel-item'>
            <img src={sanstorage} alt="sanstorage" />
        </div>
        <div className='carousel-item'>
            <img src={seedsacademia} alt="seedsacademia" />
        </div>
        <div className='carousel-item'>
            <img src={sefam} alt="sefam" />
        </div>
        <div className='carousel-item'>
            <img src={sophos} alt="sophos" />
        </div>
        <div className='carousel-item'>
            <img src={uil} alt="uil" />
        </div>
        <div className='carousel-item'>
            <img src={willshire} alt="willshire" />
        </div>
      </Carousel>
    </div>
  );
}

export default ProjectsCarousel;
