import React from 'react'
import {Navbar} from '../Components'
import Footer from '../Components/Footer'
import '../css/About.css'
import { NavLink } from 'react-router-dom'
import mission from '../Components/images/About us/mission.jpg'
import vision from '../Components/images/About us/vision.png'
import growth from '../Components/images/About us/growth.png'
import company from '../Components/images/About us/company.png'
import project from '../Components/images/About us/project.png'
import jahanzeb from '../Components/images/About us/jahanzeb.jpg'
import masood from '../Components/images/About us/Masood.jpg'
import sajid from '../Components/images/About us/sajid.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const About = () => {
  return (
   
    <div>
       <Navbar/>
       <h1 className='Aboutus'>ABOUT US</h1> 
       <hr style={{color: 'blue'}} />
       <div className='parentg1'>
        <div className='missiontext'>
          <h1 style={{color: 'black'}}>Mission Statement</h1>
          <h4 style={{color: 'grey'}}>To become the most affordable, reliable and secure DigiTel platform that will help it's customers and partners in the growth of business while opting for "DigiTel practices. We will remain committed to provide IT soutions primarily focusing on Technology Transformation, Solution Upgrades Mobility, Consolidation, Convergence and Automation</h4>
        </div>
        <div className='missionimg'>
          <img className='mission' src={mission} alt="mission" />
        </div>
       </div>
       <div className='parentg2'>
        <div className='vision'>
          <img className='visionimg' src={vision} alt="vission" />
        </div>
        <div className='visiontext'>
          <h1 style={{color: 'black'}}>Vision Statement</h1>
          <h4 style={{color: 'grey'}}>To have word "Digital" replaced by the term "DigiTel" by providing state of the art solutions to customers. Over the period of time, we vow to bring all our "Manual" customers to "Online" state, while partnering with them in their "Physical" to "Virtual" cloud initiatives.</h4>
        </div>
       </div>
       <div className='parentg3'>
        <div className='growth'>
          <img className='growthimg' src={growth} alt="growth" />
        </div>
        <div className='growthtext'>
          <div className='divtext'>
            <h1 style={{color: 'white'}}>Year On Year Growth</h1>
            <ul className='growthul'>
              <li style={{color: 'white'}}>Established in 2017, <b style={{color: 'white'}}>DigiTel Group</b> was an online store and healthcare company</li>
              <li style={{color: 'white'}}><b style={{color: 'white'}}>DigiTel Globe</b> started with only two Business Units (BU) Healthcare nad DigiTel Marketing</li>
              <li style={{color: 'white'}}>In 2020 company expanded and Pvt. Ltd firm emerged as <b style={{color: 'white'}}>DigiTel Integrated Solutions Pvt. Ltd</b></li>
              <li style={{color: 'white'}}>FY 2020-2021 company gained Top industry & Technology partnerships.</li>
              <li style={{color: 'white'}}>In the spring of 2021 UK based <b style={{color: 'white'}}>Strong Start</b>, partners, up with a digital Learning Platform to start Business Communication, <b style={{color: 'white'}}>IELTS, Cultural Adaptability, PMP, Cisco, AWS cloud practitioner courses</b>, certification preparations and hands-on on-prem and online training.</li>
              <li style={{color: 'white'}}>At the start of the Year 2022, <b style={{color: 'white'}}>DigiTel</b> opens up a branch in <b style={{color: 'white'}}>Gilgit</b> to support and serve the developing Gilgit Baltistan market.</li>
            </ul>
            </div>
        </div>
       </div>
       
       <div className='parentg4'>
        <div className='groupofcompanies'>
          <h1 style={{color: 'white'}}>The Group of Companies</h1>
          <ul className='companiesul'>
            <li style={{color: 'white'}}><b style={{color: 'white'}}>DigiTel Integrated Solutions (Pvt) Ltd</b> - Technology & Solutions</li>
            <li style={{color: 'white'}}><b style={{color: 'white'}}>DigiTel Globe</b> - Services, End User Compute, Services, O & M</li>
            <li style={{color: 'white'}}><b style={{color: 'white'}}>AMDC</b> - Dental & Cosmetic Clinics</li>
            <li style={{color: 'white'}}><b style={{color: 'white'}}>Strong Start</b> - A digital learning platform</li>
            <li style={{color: 'white'}}><b style={{color: 'white'}}>D4Dkan</b> - DigiTel stores</li>
          </ul>
        </div>
        <div className='companies'>
          <img className='companiesimg' src={company} alt="companies" />
        </div>
       </div>
       <div className='parentg5'>
        <div className='project'>
          <img className='projectimg' src={project} alt="project" />
        </div>
        <div className='projecttext'>
            <div className='noticale'>
              <h1 style={{color: 'white'}}>Noticale Projects</h1>
              <ul className='noticaleul'>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>SEFAM / Kayseria</b> - Smart Modular Datacenter at 8-Waris Rd Lahore First self-containment racking solution of region deployed with <b style={{color: 'white'}}>ATTOM Technology</b></li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>National University of Science & Technology (NUST)</b> - Modular Datacenter and Network Infrastructure at NUST Quetta Campus. Largest <b style={{color: 'white'}}>ATTOM deployment till date.</b></li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Karakoram International University (KIU)</b> - Modular Datacenter and Network Infrastructure at <b style={{color: 'white'}}>Gilgit Campus</b></li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>National Transmission & Despatch Company (NTDC)</b> - Provision of IT equipment and infrastructure</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Cotton Web Limited</b> - Deployment of SAN Solution at Primary and <b style={{color: 'white'}}>DR</b> site using <b style={{color: 'white'}}>DELL EMC</b> PowerStore 500.</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Wilshire Labs Pvt Ltd</b> - Enhanced Security & Intrusion Prevention for Datacenter (based on <b style={{color: 'white'}}>Sophos Network Security Solution</b>)</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>FBR</b> - Switching and Networking Project at <b style={{color: 'white'}}>RTO Faisalabad</b></li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Cotton Web Limited</b> - Internet Access Management and Security Project (based on <b style={{color: 'white'}}>Sangfor Technologies</b>)</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>North Texas Diabetes & Endocrinology Hospital, USA</b> - Video Consultancy and Video Documentry Projects for Patient Care and Awareness.</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>WAPDA</b> - Provision of IT hardware</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Pakistan MNP Database (Gurantee) Ltd, Islamabad</b> - Relocation of DR Site, Designing of new site, Migration of all Equipment in tumkey solution.</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Allama Iqbal Open University (AIOU) Islamabad</b> - Provisioning of Voice Over IP Telephony Solution</li>
                  <li style={{color: 'white'}}><b style={{color: 'white'}}>Albario Engineering Private Limited (AEPL)</b> - Provision of IT equipment for manufacturing & design facility</li>
              </ul>
            </div>
        </div>
       </div>
       <br /><br />
       <h1 style={{color: 'blue', fontFamily: 'sans-serif', textAlign: 'center', fontSize: '50px'}}>Our Team</h1>
       <div className='team'>
       <p>A descriptive paragraph that tells clients how good you are and proves that you are the best choice that they've made</p>
       </div>
       <div className='parentg6'>
        <div className='jahanzeb'>
            <img className='jahanzebpic' src={jahanzeb} alt="jahanzeb" />
            <h1 style={{color: 'black'}}>Jahanzeb Afzal</h1>
            <p style={{fontSize: '18px'}}>Managing Director</p>
            <NavLink to='https://www.facebook.com/jahanzeb.afzal.7'>
            <FontAwesomeIcon className='facebookabout' icon={faFacebook}></FontAwesomeIcon>
            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavLink>
              <FontAwesomeIcon className='linkedinabout' icon={faLinkedin}></FontAwesomeIcon>
            </NavLink>
        </div>
        <div className='sajid'>
          <img className='sajidimg' src={sajid} alt="sajid" />
          <h1 style={{color: 'black'}}>Sajid Mushtaq Qureshi</h1>
            <p style={{fontSize: '18px'}}>Country General Manager</p>
            <NavLink to='https://www.facebook.com/sajidmq'>
            <FontAwesomeIcon className='facebookabout' icon={faFacebook}></FontAwesomeIcon>
            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavLink to='https://www.linkedin.com/in/sajidmq/'>
              <FontAwesomeIcon className='linkedinabout' icon={faLinkedin}></FontAwesomeIcon>
            </NavLink>
        </div>
        <div className='masood'>
          <img className='masoodpic' src={masood} alt="masood" />
          <h1 style={{color: 'black'}}>Masood Ali</h1>
            <p style={{fontSize: '18px'}}>Country Sales Head</p>
          <NavLink to='https://www.facebook.com/ali.masood.161'>
            <FontAwesomeIcon className='facebookabout' icon={faFacebook}></FontAwesomeIcon>
            </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <NavLink to='https://www.linkedin.com/in/masood-ali-162a4b66/'>
              <FontAwesomeIcon className='linkedinabout' icon={faLinkedin}></FontAwesomeIcon>
            </NavLink>
        </div>
       </div>
       <Footer/>
    </div>
  )
}

export default About
