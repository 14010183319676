import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Navbar.css';
import logo from '../Components/images/logoblue.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav>
        <NavLink to="/">
          <img className='logo' src={logo} alt="digitel-logo" />
        </NavLink>
        <button className="menu-toggle" onClick={toggleMenu}>
          <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <ul className={`navbar ${isMenuOpen ? 'open' : ''}`}>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/About">About Us</NavLink></li>
          <li><NavLink to="/BusinessUnits">Specialization</NavLink></li>
          <li><NavLink to="/OurPartners">Our Partners</NavLink></li>
          <li><NavLink to="/Contact">Contact</NavLink></li>
          <li><a href="https://www.facebook.com/DigiTelGlobe" target="_blank" rel="noreferrer"><FontAwesomeIcon className='facebook' icon={faFacebook} /></a></li>
          <li><a href="https://twitter.com/digitelglobe" target="_blank" rel="noreferrer"><FontAwesomeIcon className='twitter' icon={faTwitter}></FontAwesomeIcon></a></li>
          <li><a href="https://www.instagram.com/digitelglobe/?hl=en" target="_blank" rel="noreferrer"><FontAwesomeIcon className='instagram' icon={faInstagram}></FontAwesomeIcon></a></li>
          <li><a href="https://www.youtube.com/@digitelglobe" target="_blank" rel="noreferrer"><FontAwesomeIcon className='youtube' icon={faYoutube}></FontAwesomeIcon></a></li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar;
