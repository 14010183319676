import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/ourpartners.css'
import { NavLink } from 'react-router-dom'
import ATTOM from '../Components/images/partners/ATTOM.jpeg'
import AWS from '../Components/images/partners/AWS.png'
import CITRIX from '../Components/images/partners/CITRIX.png'
import DELLEMC from '../Components/images/partners/DELLEMC.png'
import DELLtechnologies from '../Components/images/partners/DELLtechnologies.jpeg'
import FORTINET from '../Components/images/partners/FORTINET.png'
import GRANDSTREAM from '../Components/images/partners/GRANDSTREAM.png'
import H3C from '../Components/images/partners/H3C.jpeg'
import RUCKUS from '../Components/images/partners/RUCKUS.png'
import SANGFOR from '../Components/images/partners/SANGFOR.png'
import VIVANCO from '../Components/images/partners/VIVANCO.png'
import VERICOM from '../Components/images/partners/VERICOM.png'
import LENOVO from '../Components/images/partners/LENOVO.png'
import KASPERSKY from '../Components/images/partners/KASPERSKY.jpeg'
import IOTA from '../Components/images/partners/IOTA.png'
import CLOUD9 from '../Components/images/partners/CLOUD9.jpg'
import VERTIV from '../Components/images/partners/VERTIV.png'
import HP from '../Components/images/partners/HP.png'
import HPE from '../Components/images/partners/HPE.png'
import TRENDnet from '../Components/images/partners/TRENDnet.png'
import MOLEX from '../Components/images/partners/MOLEX.jpg'
import ALHUA from '../Components/images/partners/ALHUA.png'
import HIKVISION from '../Components/images/partners/HIKVISION.png'
import VMWARE from '../Components/images/partners/VMWARE.jpg'
import MICROSOFT from '../Components/images/partners/MICROSOFT.png'
import UNV from '../Components/images/partners/UNV.png'
import BROCADE from '../Components/images/partners/BROCADE.png'
import RIVERBED from '../Components/images/partners/RIVERBED.png'
import KEMP from '../Components/images/partners/KEMP.png'
import DIGITUS from '../Components/images/partners/DIGITUS.png'
import IBM from '../Components/images/partners/IBM.png'
import SOPHOS from '../Components/images/partners/SOPHOS.png'
import HUAWEI from '../Components/images/partners/HUAWEI.jpeg'
import AWAN from '../Components/images/partners/AWAN.jpeg'
import MM from '../Components/images/partners/MM.png'

const OurPartners = () => {
  return (
    <div>
      <Navbar/>

      <h1 style={{textAlign: 'center', marginTop: '50px', fontFamily: 'sans-serif', color: 'blue', fontSize: '40px'}}>Our Partners</h1>
      <hr />

      <div className='ourpartnersparent1'>
        <NavLink to='https://attom.tech/'>
        <div className='attompartners'>
          <img className='attomimg' src={ATTOM} alt="attom" />
        </div>
        </NavLink>
        <NavLink to='https://aws.amazon.com/'>
        <div className='awspartners'>
          <img className='awsimg' src={AWS} alt="aws" />
        </div>
        </NavLink>
        <NavLink to='https://www.citrix.com/'>
        <div className='citrixpartners'>
          <img className='citriximg' src={CITRIX} alt="citrix" />
        </div>
        </NavLink>
        <NavLink to='https://www.dell.com/en-pk'>
        <div className='dellemcpartners'>
          <img className='dellemcimg' src={DELLEMC} alt="dellemc" />
        </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.dell.com/en-pk'>
          <div className='delltechnologiespartner'>
            <img className='delltechnologiesimg' src={DELLtechnologies} alt="dell" />
          </div>
        </NavLink>
        <NavLink to='https://www.fortinet.com/'>
          <div className='fortinetpartner'>
            <img className='fortinetimg' src={FORTINET} alt="fortinet" />
          </div>
        </NavLink>
        <NavLink to='https://www.grandstream.com/'>
          <div className='grandstreampartner'>
            <img className='grandstreamimg' src={GRANDSTREAM} alt="grandstream" />
          </div>
        </NavLink>
        <NavLink to='https://www.h3c.com/en/'>
          <div className='h3cpartner'>
              <img className='h3cimg' src={H3C} alt="h3c" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.ruckusnetworks.com/'>
          <div className='ruckuspartners'>
            <img className='ruckusimg' src={RUCKUS} alt="ruckus" />
          </div>
        </NavLink>
        <NavLink to='https://www.sangfor.com/'>
          <div className='sangforpartners'>
            <img className='sangforimg' src={SANGFOR} alt="sangfor" />
          </div>
        </NavLink>
        <NavLink to='https://vivanco.com/'>
          <div className='vivancopartners'>
            <img className='vivancoimg' src={VIVANCO} alt="vivanco" />
          </div>
        </NavLink>
        <NavLink to='https://intl.vericomsolutions.com/'>
          <div className='vericompartners'>
            <img className='vericomimg' src={VERICOM} alt="vericom" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.lenovo.com/pk/en/'>
          <div className='lenovopartners'>
            <img className='lenovoimg' src={LENOVO} alt="lenovo" />
          </div>
        </NavLink>
        <NavLink to='https://me-en.kaspersky.com/'>
          <div className='kasperskypartners'>
            <img className='kasperskyimg' src={KASPERSKY} alt="kaspersky" />
          </div>
        </NavLink>
        <NavLink to='https://iotasolutions.io/'>
          <div className='iotapartners'>
            <img className='iotaimg' src={IOTA} alt="iota" />
          </div>
        </NavLink>
        <NavLink to='https://cloud9.gg/'>
          <div className='cloud9partners'>
            <img className='cloud9img' src={CLOUD9} alt="cloud9" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.vertiv.com/en-asia/'>
          <div className='vertivpartners'>
            <img className='vertivimg' src={VERTIV} alt="vertiv" />
          </div>
        </NavLink>
        <NavLink to='https://www.hp.com/us-en/home.html'>
          <div className='hppartners'>
            <img className='hpimg' src={HP} alt="hp" />
          </div>
        </NavLink>
        <NavLink to='https://www.hpe.com/us/en/home.html'>
          <div className='hpepartners'>
            <img className='hpeimg' src={HPE} alt="hpe" />
          </div>
        </NavLink>
        <NavLink to='https://www.trendnet.com/'>
          <div className='trendnetpartners'>
            <img className='trendnetimg' src={TRENDnet} alt="trendnet" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.molex.com/en-us/home'>
          <div className='molexpartners'>
            <img className='moleximg' src={MOLEX} alt="molex" />
          </div>
        </NavLink>
        <NavLink to='https://www.dahuasecurity.com/?us=en'>
          <div className='alhuapartners'>
            <img className='alhuaimg' src={ALHUA} alt="alhua" />
          </div>
        </NavLink>
        <NavLink to='https://www.hikvision.com/mena-en/'>
          <div className='hikvisionpartners'>
            <img className='hikvisionimg' src={HIKVISION} alt="hikvision" />
          </div>
        </NavLink>
        <NavLink to='https://www.vmware.com/sg.html'>
          <div className='vmwarepartners'>
            <img className='vmwareimg' src={VMWARE} alt="vmware" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.microsoft.com/en-pk'>
          <div className='microsoftpartners'>
            <img className='microsoftimg' src={MICROSOFT} alt="microsoft" />
          </div>
        </NavLink>
        <NavLink to='https://www.unv.org/'>
          <div className='unvpartners'>
            <img className='unvimg' src={UNV} alt="unv" />
          </div>
        </NavLink>
        <NavLink to='https://www.broadcom.com/'>
          <div className='brocadepartners'>
            <img className='brocadeimg' src={BROCADE} alt="brocade" />
          </div>
        </NavLink>
        <NavLink to='https://www.riverbed.com/'>
          <div className='riverbedpartners'>
            <img className='riverbedimg' src={RIVERBED} alt="riverbed" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://kemptechnologies.com/'>
          <div className='kemppartners'>
            <img className='kempimg' src={KEMP} alt="kemp" />
          </div>
        </NavLink>
        <NavLink to='https://www.digitus.info/en/'>
          <div className='digituspartners'>
            <img className='digitusimg' src={DIGITUS} alt="digitus" />
          </div>
        </NavLink>
        <NavLink to='https://www.ibm.com/us-en'>
          <div className='ibmpartners'>
            <img className='ibmimg' src={IBM} alt="ibm" />
          </div>
        </NavLink>
        <NavLink to='https://sophusinfo.com/'>
          <div className='sophuspartners'>
            <img className='sophosimg' src={SOPHOS} alt="sophos" />
          </div>
        </NavLink>
      </div>
      <div className='ourpartnersparent1'>
        <NavLink to='https://www.huawei.com/en/'>
          <div className='huaweipartners'>
            <img className='huaweiimg' src={HUAWEI} alt="huawei" />
          </div>
        </NavLink>
        <NavLink to='https://www.grandstream.com/'>
          <div className='grandstreampartner'>
            <img className='grandstreamimg' src={GRANDSTREAM} alt="grandstream" />
          </div>
        </NavLink>
        <NavLink to='https://www.awandistribution.com/'>
          <div className='awanpartners'>
            <img className='awanimg' src={AWAN} alt="awan" />
          </div>
        </NavLink>
        <NavLink>
          <div className='mmpartners'>
            <img className='mmimg' src={MM} alt="mm" />
          </div>
        </NavLink>
      </div>
      <br /><br /><br />
      <Footer/>
    </div>
  )
}

export default OurPartners
