import React from 'react'
import video from '../Components/videos/video.mp4'
import '../css/Projectcarousel.css'

const VideoComponent = () => {
  return (
    <div className="video-container">
    <video className="video-small" controls>
      <source src={video} type="video/mp4" />
    </video>
  </div>
);
}

export default VideoComponent;