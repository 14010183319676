import React from 'react'
import '../css/Main.css'
import main from '../Components/images/main.png'
import datacenter from '../Components/images/datacenter.png'
import socialmedia from '../Components/images/socialmedia.jpg'
import services from '../Components/images/services.jpg'
import automation from '../Components/images/automation.png'
import Carousel from '../Pages/Carousel'
import {NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser, faGaugeHigh, faBurger } from '@fortawesome/free-solid-svg-icons';
import Projectscarousel from '../Pages/Projectscarousel'
import Video from '../Pages/VideoComponent'

const Main = () => {
  return (
    <div>
      <div className='main'>
        <div className='wrapper'>
      <img className='imgmain' src={main} alt="main"/>
      </div>
        <div className='text'>
          <h1 className='welcome'>DigiTel is the new Digital</h1>
          <br />
          <p className='digiteltext'>Digitel Integrated Solutions, an initiative of Digitel Group Of Companies, is your dedicated partner in the digital era. Committed to <b style={{color: 'white'}}>affordability, reliability,</b> and <b style={{color: 'white'}}>security</b>, we collaborate with global IT partners to drive business growth and transformation. Specializing in <b style={{color: 'white'}}>Technology tansformation, Solution Upgrades, Mobility, Consolidation, Convergence, and Automation,</b>we guide clients from manual to automated, fostering a virtual state. Join us on the fast track to advancement - where affordability meets innovation.</p>
          <NavLink to='/About'>
              <button className='button'>Learn More</button>
          </NavLink>
          </div>
          </div>
        <div className='growth2'>
          <h1 style={{color: 'black'}}>Your partner in business growth</h1>
          <p className='growth2p'>DigiTel Integrated Solutions, an initiative of DigiTel Group of Companies, is your dedicated partner in digital era.Commited to provide <b>Reliability, Availability and Security,</b> we collaborate with global IT partners to drive business growth and transformation for our valued clients. Specializing in <b>Technology Transformation, Solution Upgrades, Mobility, Consolidation, Convergence, and Automation,</b> we guide our customers in a journey from manual to automated journey, fostering a virtual state. Join us on the fast track to advancement -- where affordability meets innovation.</p>
        </div>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> 
        <div className='services'>
        <div className='Technology'>
        <FontAwesomeIcon className='clock' icon={faClock} />
        <br /><br />
        <NavLink className='linkbusiness' to='/BusinessUnits'>
          <h2 className='technology'>Technology</h2>
        </NavLink>
        </div>
        <div className='Automation'>
          <FontAwesomeIcon className='user' icon={faUser}></FontAwesomeIcon>
          <br /><br />
          <NavLink className='linkautomation' to='/BusinessUnits'>
            <h2 className='automation'>Social Media Marketing (SMM)</h2>
          </NavLink>
        </div>
        <div className='deployment'>
          <FontAwesomeIcon className='gauge' icon={faGaugeHigh}></FontAwesomeIcon>
          <br/><br/>
          <NavLink className='linkservices' to='/BusinessUnits'>
            <h2 className='Services'>Services & Deployment</h2>
          </NavLink>
        </div>
        </div>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div className='Consultancy'>
            <FontAwesomeIcon className='burger' icon={faBurger}></FontAwesomeIcon>
            <br /><br />
            <NavLink className='linkconsultancy' to='/BusinessUnits'>
              <h2 className='consultancy'>Automation</h2>
            </NavLink>
        </div>
        <br /><br /><br /><br /><br /><br />
        <div className='parentdiv1'>
        <div className='solutions'>
          <div className='datacentresolutions'>
            <br />
              <h2 style={{marginLeft: '20px'}}>Technology</h2>
              <p></p>
              <p></p>
              <ul className='technologyulmain'>
                <li>Servers and Storage</li>
                <li>Network & Security Solutions</li>
                <li>Backup and Protection</li>
                <li>IT Infrastructure and Solutions</li>
                <li>Datacenter Establishment</li>
                <li>IP Telephony, Video Conferencing and Communication based Solutions</li>
              </ul>
              <div className='datacentersolutions2'>
                <ul className='technologyulmain'>
                  <li>Business Consultancy and DR Methodology</li>
                  <li>Surveillance and Monitoring Solution</li>
                  <li>Attendance Management Appliances</li>
                </ul>
              </div>
              <br /><br /><br /><br /><br /><br /><br /><br />
          </div>
        </div>
        <div className='datacenter'>
            <img className='imgdatacenter' src={datacenter} alt="datacenter" />
        </div>
        </div>
        <br /><br /><br /><br /><br />
        <div className='parent2'>
        <div className='socialmedia'>
        <div className='socialmediaimg'>
            <img className='imagesocialmedia' src={socialmedia} alt="socialmedia" />
          </div>
          <div className='textsocial'>
            <div className='socialtext'>
            <p></p>
            <h2 style={{color: 'white', marginLeft: '20px'}}>Social Media Marketing</h2>
            <br />
            <ul className='technologyulmain'>
              <li style={{color: 'white'}}>Image Building & Recognition</li>
              <li style={{color: 'white'}}>Revamping & Rebranding</li>
              <li style={{color: 'white'}}>Social Media & DigiTel Marketing</li>
              <li style={{color: 'white'}}>Pages & Group Management</li>
              <li style={{color: 'white'}}>Graphic Designing & Content writing</li>
              <li style={{color: 'white'}}>Campaigns and Promotion</li>
              <li style={{color: 'white'}}>DigiTel & Corporate Profile Building</li>
            </ul>
            </div>
            <div className='socialtext2'>
              <ul className='technologyulmain'>
                <li style={{color: 'white', marginTop: '103px'}}>Online Event and product launch</li>
              </ul>
            </div>
            <br />
          </div>
          </div>
          </div>
          <div className='parentservicesmainpage'>
              <div className='servicesmainpagetext'>
                <div className='servicesmainpagetext1'>
                  <h2 style={{color: 'white', marginLeft: '20px'}}>Services</h2>
                  <br />
                  <ul className='servicesulmain'>
                    <li style={{color: 'white'}}>Deployment & Integration Services</li>
                    <li style={{color: 'white'}}>Renewals, upgrades & Tech refreshes</li>
                    <li style={{color: 'white'}}>Subscriptions and extended warranties</li>
                    <li style={{color: 'white'}}>Outsourcing, Helpdesk services & O&M</li>
                    <li style={{color: 'white'}}>Project Management & Quality Assurance</li>
                    <li style={{color: 'white'}}>Customer care & Satisfaction</li>
                    <li style={{color: 'white'}}>Remote or International deployment</li>
                  </ul>
                </div>
                <div className='servicesmainpagetext2'>
                  <ul className='technologyulmain'>
                    <li style={{color: 'white', marginTop: '103px'}}>Videos, illustrations and Vlog Services</li>
                  </ul>
                </div>
              </div>
              <div className='servicesmainpageimagediv'>
                <img className='servicesimgmainpage' src={services} alt="services" />
              </div>
            </div>
            <div className='parentautomationmainpage'>
              <div className='automationmainpageimgdiv'>
                <img className='automationimgmainpage' src={automation} alt="automation" />
              </div>
              <div className='automationmainpagetext'>
              <div className='automationmainpagetext1'>
              <h2 style={{color: 'white', marginLeft: '20px'}}>Automation</h2>
              <br />
              <ul className='automationulmain'>
                <li style={{color: 'white'}}>Software development</li>
                <li style={{color: 'white'}}>Web Portals and E-commerce sites</li>
                <li style={{color: 'white'}}>Development of Online Selling Portal</li>
                <li style={{color: 'white'}}>App. Development & Publishing</li>
                <li style={{color: 'white'}}>Hospital Management Solutions (HMIS)</li>
                <li style={{color: 'white'}}>Attandance & Payroll Management</li>
                <li style={{color: 'white'}}>Learning Management solutions</li>
              </ul>
              </div>
              <div className='automationmainpagetext2'>
                <ul className='technologyulmain'>
                  <li style={{color: 'white', marginTop: '103px'}}>Search Engine optimization (SEO)</li>
                  <li style={{color: 'white'}}>Customized ERP and CRMs</li>
                  <li style={{color: 'white'}}>Cloud Services & Hosting</li>
                </ul>
              </div>
              </div>
            </div>
            <br /><br /><br /><br />
          <br /><br /><br /><br />
          <h1 style={{color: 'blue', fontFamily: 'sans-serif', textAlign: 'center'}}>Our Customers</h1>
          <hr />
          <br />
          <Carousel/>
          <br /><br /><br /><br />
          <h1 style={{color: 'blue', fontFamily: 'sans-serif', textAlign: 'center'}}>Our Projects</h1>
          <hr />
          <Video/>
          <Projectscarousel/>
          </div>
  )
}

export default Main;
