import React, {Component, createRef} from 'react'
import Flickity  from 'flickity'
import 'flickity/css/flickity.css'
import leisureclub from '../Components/images/Customers/leisure club.jpg'
import texas from '../Components/images/Customers/texas logo.png'
import KIU from '../Components/images/Customers/KIU-Logo.jpg'
import LUMS from '../Components/images/Customers/LUMS.png';
import AIOU from '../Components/images/Customers/AIOU.png'
import TEXPAK from '../Components/images/Customers/TEXPAK.jpg'
import COTTONWEB from '../Components/images/Customers/COTTONWEB.jpg'
import JR from '../Components/images/Customers/JR.png'
import AHF from '../Components/images/Customers/AHF.jpg'
import MG from '../Components/images/Customers/MG.png'
import LCI from '../Components/images/Customers/LCI.png'
import UOP from '../Components/images/Customers/UOP.png'
import NGC from '../Components/images/Customers/NGC.png'
import AUP from '../Components/images/Customers/ANP.jpg'
import NU from '../Components/images/Customers/NU.png'
import SEFAM from '../Components/images/Customers/SEFAM.jpg'
import PMD from '../Components/images/Customers/PMD.jpg'
import ST from '../Components/images/Customers/ST.jpg'
import wilshire from '../Components/images/Customers/wilshire.png'
import NTDC from '../Components/images/Customers/NTDC.jpg'
import MORINAGA from '../Components/images/Customers/MORINAGA.png'
import WAPDA from '../Components/images/Customers/WAPDA.jpg'
import IMGC from '../Components/images/Customers/IMGC.png'
import SHAMA from '../Components/images/Customers/SHAMA.png'
import PESCO from '../Components/images/Customers/PESCO.png'
import SS from '../Components/images/Customers/SS.png'
import KAYSERIA from '../Components/images/Customers/KAYSERIA.jpg'
import RR from '../Components/images/Customers/R&R.png'
import BI from '../Components/images/Customers/BI.png'
import BU from '../Components/images/Customers/BU.png'


class Carousel extends Component{
    constructor(props) {
        super(props);
        this.carouselRef = createRef();
    }

    componentDidMount() {
        this.flickity = new Flickity(this.carouselRef.current, {
            resize: true,
            contain: true,
            autoPlay: true,
            pauseAutoPlayOnHover: false,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: false
        });
    }

    componentWillUnmount() {
        this.flickity.destroy();
    }

    render() {
        return(
            <div ref={this.carouselRef}>
                <div className='carousel-cell'>
                <div className='leisureclubcustomer'>
            <img className='leisureclubimg' src={leisureclub} alt="leisureclub" />
        </div>
                    </div>
                <div className='carousel-cell'>
                <div className='texascustomer'>
            <img className='texasimg' src={texas} alt="texas" />
        </div>
                </div>
                <div className='carousel-cell'>
                <div className='KIUcustomer'>
            <img className='kiucustomerimg' src={KIU} alt="kiu" />
        </div>
                </div>
                <div className='carousel-cell'>
                <div className='LUMScustomer'>
            <img className='lumscustomerimg' src={LUMS} alt="lums" />
        </div>
                </div>
                <div className='carousel-cell'>
                <div className='AIOUcustomer'><img className='aioucustomerimg' src={AIOU} alt="aiou" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='TEXPAKcustomer'><img className='texpakcustomerimg' src={TEXPAK} alt="texpak" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='COTTONWEBcustomer'><img className='cottonwebcustomerimg' src={COTTONWEB} alt="cottonweb" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='JRcustomer'><img className='jrcustomerimg' src={JR} alt="jr" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='AHFcustomer'><img className='ahfcustomerimg' src={AHF} alt="ahf" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='MGcustomer'><img className='mgcustomerimg' src={MG} alt="mg" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='LCIcustomer'><img className='lcicustomerimg' src={LCI} alt="lci" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='UOPcustomer'><img className='uopcustomerimg' src={UOP} alt="uop" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='NGCcustomer'><img className='ngccustomerimg' src={NGC} alt="ngc" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='AUPcustomer'><img className='aupcustomerimg' src={AUP} alt="aup" /></div>
                </div>
                <div className='carousel-cell'>
                <div className= 'NUcustomer'><img className='nucustomerimg' src={NU} alt="nu" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='SEFAMcustomer'><img className='sefamcustomerimg' src={SEFAM} alt="sefam" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='PMDcustomer'><img className='pmdcustomerimg' src={PMD} alt="pmd" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='STcustomer'><img className='stcustomerimg' src={ST} alt="st" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='willshirecustomer'><img className='willshirecustomerimg' src={wilshire} alt="wilshire" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='NTDCcustomer'><img className='ntdccustomerimg' src={NTDC} alt="ntdc" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='MORINAGAcustomer'><img className='morinagacustomerimg' src={MORINAGA} alt="morinaga" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='WAPDAcustomer'><img className='wapdacustomerimg' src={WAPDA} alt="wapda" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='IMGCcustomer'><img className='imgccustomerimg' src={IMGC} alt="imgc" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='SHAMAcustomer'><img className='shamacustomerimg' src={SHAMA} alt="shama" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='PESCOcustomer'><img className='pescocustomerimg' src={PESCO} alt="pesco" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='SScustomer'><img className='sscustomerimg' src={SS} alt="ss" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='KAYSERIAcustomer'><img className='kayseriacustomerimg' src={KAYSERIA} alt="kayseria" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='RRcustomer'><img className='rrcustomerimg' src={RR} alt="rr" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='BIcustomer'><img className='bicustomerimg' src={BI} alt="bi" /></div>
                </div>
                <div className='carousel-cell'>
                <div className='BUcustomer'><img className='bucustomerimg' src={BU} alt="bu" /></div>
                </div>
                
                </div>
        )
    }
}



export default Carousel;
